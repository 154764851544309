<app-left-sidebar *ngIf="sideVisible">
  <app-side-nav></app-side-nav>
</app-left-sidebar>
<div class="header-body w-full h-full">
  <app-header></app-header>
  <div class="h-full w-full">
    <app-body class="square-scroll"></app-body>
  </div>
  <app-footer></app-footer>
  <app-loading-indicator></app-loading-indicator>
</div>
