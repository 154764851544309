import { Component, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { RoutingService } from 'src/app/common/routing/routing.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  navOptions = [
    {
      matIcon: false,
      icon: 'showroom_icon_black.svg',
      iconActive: 'showroom_icon_blue.svg',
      routerLink: this.routingService.getRouterLink('/showrooms'),
      label: `All showrooms`,
    },
  ];

  constructor(
    private routingService: RoutingService,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  goHome() {
    this.routingService.go('/showrooms');
  }

  helpCenter() {
    window.open('https://intercom.help/vibesolutions/en/', '_blank');
  }
}
