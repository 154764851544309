<div [matTooltip]="name || toolTip" class="menu-button" [ngClass]="{ highlight: highlight }" (click)="togglePanel()">
  <mat-icon *ngIf="icon && !isSVGIcon">{{ icon }}</mat-icon>
  <mat-icon svgIcon="{{ icon }}" *ngIf="icon && isSVGIcon"></mat-icon>
  <span *ngIf="name" style="margin-left: 0.5em">{{ name }}</span>
</div>
<div
  [id]="id"
  [ngClass]="{ visible: panelVisible, left: position === 'left', right: position === 'right' }"
  #filterPanel
  class="panel"
>
  <ng-content></ng-content>
</div>
