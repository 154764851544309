export const environment = {
  production: false,
  apiBaseUrl: 'https://showroom-dev.vibeiq.com/api-dev',
  showroomAppHost: 'https://showroom-dev.vibeiq.com',
  userPoolRegion: 'us-east-1',
  userPoolId: 'us-east-1_x8hXZUsL6',
  userPoolWebClientId: '2oubek96sue5rsncovtqhs3scm',
  name: 'DEVELOPMENT',
  googleAnalyticsKey: 'UA-179147959-1',
  intercomAppId: 'e2uyp9md',
  domain: '.vibeiq.com',
  websocketService: 'wss://wupwsq5l7c.execute-api.us-east-1.amazonaws.com/dev',
  appName: 'Showroom',
  ngrxHistory: 2,
  loginUrl: '', // only used by feature branches
  thumbnailBaseUrl: 'https://api.vibeiq.com/dev/api',
};
