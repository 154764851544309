<div class="header">
  <div class="page-title">Projects</div>
  <div class="tab-nav">
    <!-- <div class="tab">All</div> -->
    <!-- <div class="tab selected">Active</div>
    <div class="tab">Archived</div> -->
  </div>
</div>
<div class="body">
  <div class="search">
    <app-search-bar></app-search-bar>
  </div>
  <div class="grid">
    <app-workspace-card *ngFor="let workspace of workspaces$ | async" [workspace]="workspace"> </app-workspace-card>
  </div>
</div>
