import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  public ngOnInit() {
    this.matIconRegistry.addSvgIcon(
      'item-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/item-placeholder.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'showroom-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/showroom-placeholder.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'session-request',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/session-request.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'session-end',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/session-end.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'session-extend',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/session-extend.svg'),
    );
  }
}
