import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BodyComponent } from './body/body.component';
import { MainComponent } from './main.component';
import { AuthModule } from '../common/auth/auth.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatCommonModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrgSelectorComponent } from './header/org-selector/org-selector.component';
import { HeaderUserMenuComponent } from './header/header-user-menu/header-user-menu.component';
import { RouterModule } from '@angular/router';
import { PageHeaderComponent } from './header/page-header/page-header.component';
import { PageHeaderService } from './header/page-header/page-header.service';
import { HelpSelectorComponent } from './header/header-help/help-selector.component';
import { SelectOrgComponent } from './select-org/select-org.component';
import { ComponentsModule } from '../common/components/components.module';
import { UserSessionsModule } from '../common/user-sessions/user-sessions.module';
import { LoadingIndicatorModule } from '../common/loading-indicator/loading-indicator.module';
import { RootStoreModule } from '../root-store';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { WorkspacesModule } from '../workspaces/workspaces.module';
import { SideNavComponent } from './side-nav/side-nav.component';
import { WorkspaceSelectorComponent } from './side-nav/workspace-selector/workspace-selector.component';
import { UserProfileModule } from '@common/user-profile/user-profile.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    MainComponent,
    OrgSelectorComponent,
    HeaderUserMenuComponent,
    PageHeaderComponent,
    HelpSelectorComponent,
    SelectOrgComponent,
    SideNavComponent,
    WorkspaceSelectorComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    AuthModule,
    MatSidenavModule,
    MatCommonModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    UserSessionsModule,
    LoadingIndicatorModule,
    RootStoreModule,
    WorkspacesModule,
    UserProfileModule,
  ],
  providers: [PageHeaderService],
  exports: [MainComponent],
})
export class MainModule {}
