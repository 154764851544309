import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable } from 'rxjs';
import { ShowroomsActions, ShowroomsSelectors } from 'src/app/showrooms/showrooms-store';
import { RoutingService } from '../../../common/routing/routing.service';
import { PageHeaderConfig, PageHeaderService } from './page-header.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  showroomEdit = false;

  showroom: any;
  editName = false;
  @ViewChild('editNameInput') editNameInput: ElementRef;
  name: string;
  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private store: Store<RootStoreState.State>,
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private pageHeaderService: PageHeaderService,
  ) {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.showroomEdit = evt?.urlAfterRedirects?.includes('showrooms/edit') ? true : false;
      }
    });
  }

  ngOnInit() {
    this.store.select(ShowroomsSelectors.currentShowroom).subscribe((showroom) => {
      this.showroom = showroom;
      this.form.get('name').setValue(showroom?.name);
    });
  }

  startNameEdit() {
    this.editName = true;
    setTimeout(() => {
      this.editNameInput.nativeElement.focus();
    }, 100);
  }
  endEditName() {
    this.editName = false;
    const name = this.form.controls.name.value;
    this.showroom = { ...this.showroom, name: name };
    this.store.dispatch(ShowroomsActions.updateShowroom({ id: this.showroom.id, changes: { name: name } }));
  }

  gotoHome() {
    this.routingService.go('/');
  }
}
