import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions, WorkspacesSelectors } from '../workspaces-store';
import { Workspace } from '../workspaces-store/workspaces.state';

@Component({
  selector: 'app-workspaces',
  templateUrl: './workspaces.component.html',
  styleUrls: ['./workspaces.component.scss'],
})
export class WorkspacesComponent implements AfterViewInit {
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  public workspaces$: Observable<Array<Workspace>>;

  subscribe() {
    this.workspaces$ = combineLatest([
      this.searchBar.valueChange.pipe(startWith('')),
      this.store.select(WorkspacesSelectors.workspaces),
    ]).pipe(
      map(([searchTerm, properties]) => {
        const keys = 'name';
        return properties.filter((item) =>
          keys.split(',').some((key) => item.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(item[key])),
        );
      }),
    );
    this.store.dispatch(WorkspacesActions.loadCurrentWorkspaceSuccess({ workspace: null }));
  }
  constructor(private store: Store<RootStoreState.State>) {}
  ngAfterViewInit(): void {
    setTimeout(() => this.subscribe(), 0); // handle value change error
  }
}
