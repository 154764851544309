import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { BackingAssortmentItemActionTypes } from './backing-assortment.actions';
import { AssortmentsService } from '../../assortments.service';
import { AssortmentsActions } from '..';

@Injectable()
export class BackingAssortmentEffects {
  constructor(
    private actions$: Actions,
    private assortmentService: AssortmentsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadBackingAssortmentItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackingAssortmentItemActionTypes.LOAD_BACKING_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(AssortmentsService.getAssortment(action.assortmentId)).pipe(
          map((assortment) => AssortmentsActions.loadBackingAssortmentSuccess({ assortment })),
          catchError((error) => observableOf(AssortmentsActions.loadBackingAssortmentFailure({ error }))),
        );
      }),
    ),
  );

  addItemsToBackingAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackingAssortmentItemActionTypes.ADD_ITEMS_TO_BACKING_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(
          this.assortmentService.addItemsToAssortment(store.assortments.backingAssortment.id, action.itemIds),
        ).pipe(
          map((assortmentItems) => AssortmentsActions.addItemsToBackingAssortmentSuccess({ assortmentItems })),
          catchError((error) => observableOf(AssortmentsActions.addItemsToBackingAssortmentFailure({ error }))),
        );
      }),
    ),
  );
  removeItemsFromBackingAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackingAssortmentItemActionTypes.REMOVE_ITEMS_FROM_BACKING_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(
          this.assortmentService.removeItemsFromAssortment(store.assortments.backingAssortment.id, action.itemIds),
        ).pipe(
          switchMap(async (removedItemIds) => {
            const assortmentItemids = Object.values(store.assortments.backingAssortmentItems.entities)
              .filter((ai) => removedItemIds.includes(ai.itemId))
              .map((ai) => ai.id);
            return assortmentItemids;
          }),
          map((ids) => AssortmentsActions.removeItemsFromBackingAssortmentSuccess({ ids })),
          catchError((error) => observableOf(AssortmentsActions.removeItemsFromBackingAssortmentFailure({ error }))),
        );
      }),
    ),
  );
}
