import { ObjectUtil } from '@contrail/util';

export class ItemData {
  constructor(obj: any) {
    if (obj.item) {
      this.loadFromAssortmentItem(obj);
    } else {
      this.loadFromItem(obj);
    }
  }

  public properties: any = {};
  public id;
  public item;
  public projectItem;
  public options: Array<ItemData> = [];
  public content: Array<any>;
  public assortmentItem: any;
  public thumbnail: string;

  loadFromItem(item) {
    if (!item) {
      return;
    }
    this.item = item;
    this.properties = Object.assign(this.properties, { ...item });
    this.properties.itemName = item.name;
    this.thumbnail = this.getThumbnail(item);
    this.properties.thumbnail = this.thumbnail;
    this.properties.id = item.itemId;
    this.properties.itemId = item.id;
    this.id = item.id;
  }

  loadFromAssortmentItem(assortmentItem) {
    if (!assortmentItem.item) {
      return;
    }
    this.assortmentItem = ObjectUtil.cloneDeep(assortmentItem);
    delete this.assortmentItem.item;

    this.item = assortmentItem.item;

    this.properties = Object.assign(
      this.properties,
      { ...assortmentItem.item },
      { ...assortmentItem.projectItem },
      { ...assortmentItem },
    );
    this.properties.itemName = assortmentItem.item.name;
    this.thumbnail = this.getThumbnail(assortmentItem.item);
    this.properties.thumbnail = this.thumbnail;
    this.properties.id = assortmentItem.itemId;
    this.properties.itemId = assortmentItem.itemId;
    this.properties.assortmentItemId = assortmentItem.id; // HOLD ON TO THE SOURCE ASSORTMENT ITEM ID

    if (assortmentItem.projectItem) {
      this.projectItem = assortmentItem.projectItem;
      this.properties.projectItemId = assortmentItem.projectItem?.id;
      this.properties.projectId = assortmentItem.projectItem?.projectId;
    }

    // removed the item object from properties
    delete this.properties.item;

    this.id = assortmentItem.item.id;
    // this.properties.name = obj.item.optionName || this.properties.itemName;
  }

  getName() {
    return `${this.properties.itemName} ${this.properties.optionName ? '(' + this.properties.optionName + ')' : ''}`.trim();
  }
  getThumbnail(obj) {
    let thumb;
    thumb = obj.mediumViewableDownloadUrl;
    if (!thumb) {
      const viewable = obj.primaryViewable;
      if (viewable) {
        thumb =
          // viewable.smallViewable?.downloadUrl ||
          // viewable.mediumViewable?.downloadUrl ||
          viewable.primaryFile?.downloadUrl;
      }
    }
    return thumb;
  }
}
