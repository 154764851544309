import { Injectable } from '@angular/core';
import { Entities, Request } from '@contrail/sdk';
import { Showroom } from './showrooms-store/showrooms.state';

@Injectable({
  providedIn: 'root',
})
export class ShowroomsService {
  constructor() {}

  public async getShowrooms(workspaceId: string) {
    return new Entities().get({
      entityName: 'showroom',
      criteria: { rootWorkspaceId: workspaceId, isTrashed: false },
      relations: [
        'createdBy',
        'updatedBy',
        'showroomLayouts',
        'showroomLayouts.previewFile',
        'latestShowroomSessionRequest',
      ],
    });
  }
  public async getShowroomById(id: string) {
    return new Entities().get({
      entityName: 'showroom',
      id,
      relations: ['showroomLayouts', 'latestShowroomSessionRequest'],
    });
  }
  public async createShowroom(showroom: Showroom) {
    return new Entities().create({ entityName: 'showroom', object: showroom });
  }
  public async deleteShowroom(showroom: Showroom) {
    await new Entities().delete({ entityName: 'showroom', id: showroom.id });
    return showroom;
  }
  public async updateShowroom(id: string, changes: Showroom) {
    return new Entities().update({ entityName: 'showroom', id, object: changes });
  }

  public async getSessionRequests(showroomId: string) {
    return new Entities().get({ entityName: 'showroom-session-request', criteria: { showroomId } });
  }
  public async getSessionRequestById(id: string) {
    return new Entities().get({ entityName: 'showroom-session-request', id });
  }
  public async createSessionRequest(showroomId: string) {
    return new Entities().create({ entityName: 'showroom-session-request', object: { showroomId } });
  }
  public async extendLeaseRequest(id: string, secondsToExtend: number) {
    const url = `/showroom-session-requests/${id}/extend-lease`;
    const body = JSON.stringify({ secondsToExtend: secondsToExtend });
    const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };
    return Request.request(url, { method: 'PUT', body, headers });
  }
  public async cancelSessionRequest(id: string) {
    const url = `/showroom-session-requests/cancel/${id}`;
    return Request.request(url, { method: 'DELETE' });
  }
}
