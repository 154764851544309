import { createAction, props } from '@ngrx/store';
import { Assortment } from '../assortments.state';
import { AssortmentItem } from './backing-assortment.state';

export enum BackingAssortmentItemActionTypes {
  LOAD_BACKING_ASSORTMENT = '[Backing Assortment] Load Backing Assortment',
  LOAD_BACKING_ASSORTMENT_SUCCESS = '[Backing Assortment] Load Backing Assortment Success',
  LOAD_BACKING_ASSORTMENT_FAILURE = '[Backing Assortment] Load Backing Assortment Failure',

  ADD_ITEMS_TO_BACKING_ASSORTMENT = '[Backing Assortment] Add Items to Backing Assortment',
  ADD_ITEMS_TO_BACKING_ASSORTMENT_SUCCESS = '[Backing Assortment] Add Items to Backing Assortment Success',
  ADD_ITEMS_TO_BACKING_ASSORTMENT_FAILURE = '[Backing Assortment] Add Items to Backing Assortment Failure',

  REMOVE_ITEMS_FROM_BACKING_ASSORTMENT = '[Backing Assortment] Remove Items from Backing Assortment',
  REMOVE_ITEMS_FROM_BACKING_ASSORTMENT_SUCCESS = '[Backing Assortment] Remove Items from Backing Assortment Success',
  REMOVE_ITEMS_FROM_BACKING_ASSORTMENT_FAILURE = '[Backing Assortment] Remove Items from Backing Assortment Failure',

  UPDATE_BACKING_ASSORTMENT_ITEM = '[Backing Assortment] Update Backing Assortment Item',
  UPDATE_BACKING_ASSORTMENT_ITEM_SUCCESS = '[Backing Assortment] Update Backing Assortment Item Success',
  UPDATE_BACKING_ASSORTMENT_ITEM_FAILURE = '[Backing Assortment] Update Backing Assortment Item Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const loadBackingAssortment = createAction(
  BackingAssortmentItemActionTypes.LOAD_BACKING_ASSORTMENT,
  props<{ assortmentId: string }>(),
);

export const loadBackingAssortmentSuccess = createAction(
  BackingAssortmentItemActionTypes.LOAD_BACKING_ASSORTMENT_SUCCESS,
  props<{ assortment: Assortment }>(),
);

export const loadBackingAssortmentFailure = createAction(
  BackingAssortmentItemActionTypes.LOAD_BACKING_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);
////////////////////////////////////////////////////////////////////////////////
export const addItemsToBackingAssortment = createAction(
  BackingAssortmentItemActionTypes.ADD_ITEMS_TO_BACKING_ASSORTMENT,
  props<{ itemIds: Array<string> }>(),
);

export const addItemsToBackingAssortmentSuccess = createAction(
  BackingAssortmentItemActionTypes.ADD_ITEMS_TO_BACKING_ASSORTMENT_SUCCESS,
  props<{ assortmentItems: AssortmentItem }>(),
);

export const addItemsToBackingAssortmentFailure = createAction(
  BackingAssortmentItemActionTypes.ADD_ITEMS_TO_BACKING_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);
////////////////////////////////////////////////////////////////////////////////
export const removeItemsFromBackingAssortment = createAction(
  BackingAssortmentItemActionTypes.REMOVE_ITEMS_FROM_BACKING_ASSORTMENT,
  props<{ itemIds: Array<string> }>(),
);

export const removeItemsFromBackingAssortmentSuccess = createAction(
  BackingAssortmentItemActionTypes.REMOVE_ITEMS_FROM_BACKING_ASSORTMENT_SUCCESS,
  props<{ ids: Array<string> }>(),
);

export const removeItemsFromBackingAssortmentFailure = createAction(
  BackingAssortmentItemActionTypes.REMOVE_ITEMS_FROM_BACKING_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);
////////////////////////////////////////////////////////////////////////////////
