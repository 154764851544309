import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Assortment } from './assortments-store/assortments.state';

@Injectable({
  providedIn: 'root',
})
export class AssortmentsService {
  constructor() {}

  public static async getAssortment(id: string) {
    let assortment: any;
    const params: any = {
      entityName: 'assortment',
      id,
      relations: [
        'assortmentItems',
        'assortmentItems.item',
        'assortmentItems.item.content',
        'assortmentItems.item.content.largeViewable',
        'assortmentItems.item.content.mediumViewable',
        'assortmentItems.item.content.primaryFile',
        'assortmentItems.projectItem',
        // 'assortmentItems.item.primaryViewable',
        // 'assortmentItems.item.primaryViewable.primaryFile,',
        // 'assortmentItems.item.primaryViewable.smallViewable',
      ],
    };
    // params.cacheMode = 'USE';
    assortment = await new Entities().get(params);
    if (assortment.itemsDownloadURL) {
      const response = await fetch(assortment.itemsDownloadURL);
      const assortmentItems = await response.json();
      assortment.assortmentItems = assortmentItems;
    }
    return assortment;
  }
  public async getAssortments(workspaceId: string) {
    const criteria = { workspaceId, isArchived: false };
    return from(new Entities().get({ entityName: 'assortment', criteria, relations: ['sourceAssortments'] }))
      .pipe(
        tap((assortments) => {
          assortments.sort((a1, a2) => (a1.name?.toUpperCase() > a2.name?.toUpperCase() ? 1 : -1));
        }),
      )
      .toPromise();
  }
  public async getAssortmentById(id: string) {
    return new Entities().get({ entityName: 'assortment', id });
  }

  public async createAssortment(assortment: Assortment) {
    return new Entities().create({ entityName: 'assortment', object: assortment });
  }
  public async deleteAssortment(assortment: Assortment) {
    await new Entities().delete({ entityName: 'assortment', id: assortment.id });
    return assortment;
  }
  public async updateAssortment(id: string, changes: Assortment) {
    return new Entities().update({ entityName: 'assortment', id, object: changes });
  }

  public async addItemsToAssortment(assortmentId, itemIds) {
    const options = {
      entityName: 'assortment',
      id: assortmentId,
      relation: 'items',
      object: { itemIds },
    };

    return new Entities().create(options);
  }
  public async removeItemsFromAssortment(assortmentId, itemIds) {
    const removedItemIds: Array<any> = [];
    for (const itemId of itemIds) {
      const options = {
        entityName: 'assortment',
        id: assortmentId,
        relation: 'items',
        relationId: itemId,
      };
      try {
        const result = await new Entities().delete(options);
        removedItemIds.push(result.deletedObjectId);
      } catch (e) {
        console.log('Could not remove item from assortment: ', e);
      }
    }
    console.log('removedItemIds:  ', removedItemIds);
    return removedItemIds;
  }
}
