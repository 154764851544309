import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface Workspace {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  name?: string;
}

interface WorkspacesState extends EntityState<Workspace> {}

export interface State {
  workspaces: WorkspacesState;
  currentWorkspace: Workspace;
}
export const workspacesEntityAdapter: EntityAdapter<Workspace> = createEntityAdapter<Workspace>({});

export const workspacesInitialState = {
  workspaces: workspacesEntityAdapter.getInitialState({}),
  currentWorkspace: null,
};
