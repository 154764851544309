import { Injectable } from '@angular/core';
import { Hub } from 'aws-amplify/utils';
import { AuthUser, getCurrentUser, signOut } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Entities, setConfig, ContrailConfig, getConfig, Request } from '@contrail/sdk';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { AuthActions } from './auth-store';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
export interface AuthContext {
  user?: any;
  currentOrg?: any;
  token?: string;
  sharedLink?: any;
}
export interface User {
  email?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
  profilePhoto?: string;
  orgs?: Array<OrgMembership>;
}

export interface OrgMembership {
  orgId: string;
  orgSlug: string;
  role: string;
  orgName?: string;
  isArchived?: boolean;
}

export interface OrgConfig {
  hideAdminConsoleFromNonOrgAdmins: boolean;
}

export interface CurrentOrg extends OrgMembership {
  orgConfig: OrgConfig;
}

let LOGIN_URL;
if (environment.production) {
  LOGIN_URL = 'https://login.vibeiq.com';
} else if (environment.name === 'STAGING') {
  LOGIN_URL = 'https://login-staging.vibeiq.com';
} else if (environment.name === 'DEVELOPMENT') {
  LOGIN_URL = 'https://login-dev.vibeiq.com';
} else if (['DEVELOPER-ENV', 'DEVELOPMENT-LOCAL', 'PRODUCTION-LOCAL', 'DEFAULT - LOCAL'].includes(environment.name)) {
  LOGIN_URL = 'http://localhost:4208';
} else if (environment.name === 'FEATURE_BRANCH') {
  console.log('computing dynamic login url');
  console.log(environment.loginUrl);
  LOGIN_URL = environment.loginUrl;
} else {
  throw new Error(`Environment is invalid. Login URL can not be set. environment name:${environment.name}`);
}

function getApiGateway() {
  return getConfig().apiGateway;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string;
  private user: User;
  private currentOrg: OrgMembership;
  private userPromise;
  private shareToken;

  private authContextSubject: Subject<AuthContext> = new BehaviorSubject(null);
  public authContext: Observable<AuthContext> = this.authContextSubject.asObservable();
  private authContextObject: AuthContext = {};

  private returnUrl = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private store: Store<RootStoreState.State>,
    private cookieService: CookieService,
    private snackBar: MatSnackBar,
  ) {
    // fix for localhost development: accept cookies from a URL parameter
    // assumption: authentication cookies are checked by the backend for validity
    // so even if an attacker injects these cookies, they will be rejected
    const urlParams = new URLSearchParams(window.location.search);
    const accessTokenCookieParam = urlParams.get('vibeAccessToken');
    const refreshTokenCookieParam = urlParams.get('vibeRefreshToken');
    console.debug('access token cookie param:', accessTokenCookieParam);
    console.debug('refresh token cookie param:', refreshTokenCookieParam);
    if (accessTokenCookieParam && refreshTokenCookieParam) {
      this.setAccessToken(accessTokenCookieParam);
      this.setRefreshToken(refreshTokenCookieParam);
      console.debug('the cookies are now', document.cookie);
    }

    Hub.listen('auth', this.authStateListener);
    const service = this;
    setConfig({
      authTokenResolver: {
        resolveAuthToken() {
          return service.resolveJWTToken();
        },
      },
      apiGateway: environment.apiBaseUrl,
      errorHandler: {
        async handle(responseObject: any, errorJson: any, retryCallback) {
          return await service.handleError(responseObject, errorJson, retryCallback);
        },
      },
      networkErrorHandler: {
        async handle(error: any) {
          console.error('a network error was encountered. Printing snack bar', error);
          service.snackBar.open('A network error was encountered', '', { duration: 5000 });
        },
      },
    });
  }

  private async handleError(errorResponse: any, errorJson: any, retryCallback): Promise<any> {
    console.log('Taking over handling API error in the auth service from SDK');
    if (errorResponse.status === 403 && errorJson?.errorCode === 'SHARE_LINK_CONSTRAINT') {
      console.log('Invalid token detected');
      await this.router.navigate(['/no-org-found']);
      return Promise.resolve(null);
    }
    if (errorResponse.status === 403) {
      // try to reauthenticate using the refresh token
      const token = await this.refreshAccessToken();
      this.setAccessToken(token);
      const response = await retryCallback();
      if (response.ok) {
        const text = await response.text();
        const json = text.length ? JSON.parse(text) : null;
        console.log('**AUTH SERVICE** Access token refresh successful');
        return Promise.resolve(json);
      } else {
        console.error('Failed on a retry... Logging the user out');
        await this.logout();
      }
    } else if (errorResponse.status === 404) {
      return Promise.resolve(null);
    } else {
      throw new Error('SDK ERROR ENCOUNTERED and not resolved by auth service error handler.');
    }
  }

  public async setShareToken(shareToken: string) {
    if (!shareToken) {
      return;
    }
    this.shareToken = shareToken;
  }

  public async loadShareLink() {
    if (!this.shareToken) {
      return;
    }
    const sharedLink = await new Entities().get({ entityName: 'shared-link', id: this.shareToken });
    // this.authContextObject.sharedLink = sharedLink;
    this.store.dispatch(AuthActions.setSharedLink({ sharedLink }));
  }

  public async getAuthContext(): Promise<AuthContext> {
    this.authContextObject = {
      ...this.authContextObject,
      token: await this.getToken(),
    };
    return Promise.resolve(this.authContextObject);
  }

  public async getToken(): Promise<string> {
    return this.resolveJWTToken();
  }

  public async loadToken(): Promise<string> {
    this.token = await this.resolveJWTToken();
    setConfig({
      apiUserToken: this.token,
      apiGateway: environment.apiBaseUrl,
    });
    return this.token;
  }

  private async resolveJWTToken(): Promise<string> {
    // don't log out if this is the initial auth guard step
    // ASSUMPTION: '/' url only happens at the initial auth guard step
    // if this assumption is false, the user will not be logged out
    // in the event of a bad token
    const shouldLogout = this.router.url !== '/';
    let token = this.cookieService.get('vibeAccessToken');

    // ensure token is not expired
    let tokenObject: any;

    // if the token is not valid attempt a refresh
    try {
      tokenObject = jwt_decode(token);
    } catch (decodeError) {
      console.error('Could not decode JWT token. Attempting a refresh...');
      try {
        token = await this.refreshAccessToken();
        tokenObject = jwt_decode(token);
      } catch (refreshError) {
        console.error('Could not refresh jwt token. Printing error and Logging out...');
        console.error(refreshError);
        console.error(decodeError);
        if (shouldLogout) {
          await this.logout();
        }
      }
      // if we reach this, we refreshed the token correctly.
    }

    // if the token is valid but expired
    if (this.isTokenExpired(tokenObject)) {
      console.log('access token expired... attempting a refresh');
      token = await this.refreshAccessToken();
      if (!token) {
        console.log('refresh failed. Logging out...');
        if (shouldLogout) {
          await this.logout();
        }
      }
    } else {
    }
    if (this.shareToken) {
      token += `:shareToken=${this.shareToken}?timestamp=${Date.now()}`;
    }
    return token;
  }

  async refreshAccessToken(): Promise<string> {
    try {
      const response = (await this.requestRefreshedAccessToken().toPromise()) as any;
      return await this.setAccessToken(response.accessToken);
    } catch (e) {
      console.log("failed to refresh the user's access token.");
      console.error(e);
    }
    return null;
  }

  public async getCurrentUser(): Promise<User> {
    if (this.user) {
      return Promise.resolve(this.user);
    } else {
      if (!this.userPromise) {
        this.userPromise = this.loadCurrentUser();
      } else {
      }
      return this.userPromise;
    }
  }

  /**
   * refresh the user data
   */
  public updateUser(user) {
    this.user = user;
    this.authContextObject = { ...this.authContextObject, user };
    this.authContextSubject.next(this.authContextObject);
    this.store.dispatch(AuthActions.setAuthContext({ authContext: this.authContextObject }));
  }
  public refreshUser() {
    this.loadCurrentUser();
  }
  private async loadCurrentUser(): Promise<User> {
    this.user = (await this.http.get(environment.apiBaseUrl + '/users/current').toPromise()) as User;
    if (this.user.orgs) {
      this.user.orgs = this.user.orgs?.filter((o) => o.orgSlug);
      this.user.orgs.sort((o1, o2) => (o1.orgSlug > o2.orgSlug ? 1 : -1));
    }
    this.authContextObject.user = this.user;
    // this.store.dispatch(AuthActions.setAuthContext({ authContext: ObjectUtil.mergeDeep({}, this.authContextObject) }));
    return Promise.resolve(this.user);
  }

  /** Currently only called from UserResolver. */
  public async setCurrentOrg(org: any): Promise<void> {
    console.log('setCurrentOrg: ', org);
    localStorage.setItem('lastOrgSlug', org?.orgSlug);
    const orgSlug = org.orgSlug;
    setConfig({ orgSlug });

    const orgConfigsOfCurrentOrg = await Request.request('/org-configs', {});
    const orgConfig = this.buildOrgConfig(orgConfigsOfCurrentOrg);
    const currentOrg = { ...org, orgConfig };
    this.currentOrg = currentOrg;

    this.authContextObject = {
      currentOrg: currentOrg,
      user: this.user,
    };

    this.authContextSubject.next(this.authContextObject);
    this.store.dispatch(AuthActions.setAuthContext({ authContext: this.authContextObject }));
  }

  buildOrgConfig(orgConfigs: Array<OrgConfig>): OrgConfig {
    if (orgConfigs.length !== 1) return;
    const orgConfig = orgConfigs[0];

    return {
      hideAdminConsoleFromNonOrgAdmins: Boolean(orgConfig.hideAdminConsoleFromNonOrgAdmins),
    };
  }

  public getCurrentOrg(): any {
    return this.currentOrg;
  }
  public getRole() {
    return this.currentOrg.role;
  }
  public isAdmin() {
    return this.getRole() === 'ADMIN';
  }
  public async logout() {
    await this.clearCognitoData();
    console.log('deleting cookies');
    this.cookieService.delete('vibeAccessToken', '/', environment.domain);
    this.cookieService.delete('vibeRefreshToken', '/', environment.domain);
    // temporary manual deletion of erroneous cookies
    this.cookieService.delete('vibeAccessToken', '/', 'hub-dev.vibe.com');
    this.cookieService.delete('vibeRefreshToken', '/', 'hub-dev.vibe.com');
    this.cookieService.delete('vibeAccessToken', '/', 'admin-dev.vibeiq.com');
    this.cookieService.delete('vibeRefreshToken', '/', 'admin-dev.vibeiq.com');
    this.cookieService.delete('vibeAccessToken', '/', 'boards-dev.vibeiq.com');
    this.cookieService.delete('vibeRefreshToken', '/', 'boards-dev.vibeiq.com');
    this.cookieService.delete('vibeAccessToken', '/', 'showcase-manager-dev.vibeiq.com');
    this.cookieService.delete('vibeRefreshToken', '/', 'showcase-manager-dev.vibeiq.com');
    this.token = null;
    this.user = null;
    this.currentOrg = null;
    this.authContextObject = {};
    await this.redirectToLogin();
  }

  public async clearCognitoData() {
    console.log('clearing cognito data!');
    try {
      const user: AuthUser = await getCurrentUser();
      if (user) {
        await signOut();
      }
    } catch (err) {
      console.error('error logging out: ', err);
    }
  }
  authStateListener = async (data) => {
    switch (data.payload.event) {
      case 'signedIn':
        console.info('Auth: user signed in');
        const idToken = (await cognitoUserPoolsTokenProvider.getTokens()).idToken.toString();
        await this.authenticateUser(idToken);
        this.returnUrl = this.route?.snapshot?.queryParams?.returnUrl || '/';
        console.log('return url after login:', this.returnUrl);
        document.location.href = this.returnUrl;
        break;
      case 'signedUp':
        console.info('user signed up');
        break;
      case 'signedOut':
        console.info('user signed out');
        break;
      default:
        console.info('authStateListener >>>', data.payload.event);
        break;
    }
  };

  public isTokenExpired(tokenObject: any) {
    return tokenObject.exp && Date.now() > tokenObject.exp * 1000;
  }

  public isSignedIn(): boolean {
    const cookiesToCheck = ['vibeAccessToken', 'vibeRefreshToken'];
    for (const cookieName of cookiesToCheck) {
      try {
        const theToken = this.cookieService.get(cookieName);
        if (!theToken) {
          console.log(`isSignedIn: no ${cookieName} token`);
          return false;
        }
        if (this.isTokenExpired(jwt_decode(theToken))) {
          console.log(`isSignedIn: ${cookieName} expired`);
          return false;
        }
      } catch (e) {
        console.log('isSignedIn: error while decoding jwt');
        console.error(e);
        return false;
      }
    }
    return true;
  }

  requestRefreshedAccessToken() {
    const url = getApiGateway() + '/auth/jwt_refresh/';
    return this.http.post(
      url,
      `{
      "refreshToken": "${this.cookieService.get('vibeRefreshToken')}"
     }`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async setAccessToken(accessToken: string): Promise<string> {
    const accessTokenObject = jwt_decode(accessToken) as any;
    this.cookieService.set('vibeAccessToken', accessToken, {
      expires: new Date(accessTokenObject.exp * 1000),
      path: '/',
      domain: environment.domain,
    });
    setConfig({
      apiUserToken: accessToken,
    });
    return accessToken;
  }

  public async setRefreshToken(refreshToken): Promise<void> {
    const refreshTokenExpiration = new Date((jwt_decode(refreshToken) as any).exp * 1000);
    this.cookieService.set('vibeRefreshToken', refreshToken, {
      expires: refreshTokenExpiration,
      path: '/',
      domain: environment.domain,
    });
  }

  public redirectToLogin() {
    const returnUrl = this.router.url || '/';
    const loginRedirect = LOGIN_URL + '/login?returnUrl=' + environment.showroomAppHost + '/' + returnUrl;
    console.log('redirecting to login with return Url of', loginRedirect);
    document.location.href = loginRedirect;
  }

  public getLoginUrl(): string {
    return LOGIN_URL;
  }

  async authenticateUser(cognitoAccessToken) {
    const config: ContrailConfig = getConfig();

    console.log('authenticateUser: ', config.apiGateway);
    const url = config.apiGateway + '/auth/cognito/';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: `{
       "accessToken": "${cognitoAccessToken}"
      }`,
    });

    return await response.json().then((data) => {
      console.log('authenticateUser: setting tokens ', environment.domain);
      this.setAccessToken(data.accessToken);
      this.setRefreshToken(data.refreshToken);
      return data.accessToken;
    });
  }
}
