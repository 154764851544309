import { State } from './workspaces.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as WorkspacesActions from './workspaces.actions';
import { workspacesEntityAdapter } from './workspaces.state';
import { workspacesInitialState } from './workspaces.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    workspaces: workspacesEntityAdapter.setAll(data, state.workspaces),
    workspacesLoaded: true,
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    workspaces: workspacesEntityAdapter.removeAll(state.workspaces),
    workspacesLoaded: false,
  };
};
const add = (state: State, { workspace }) => {
  return {
    ...state,
    workspaces: workspacesEntityAdapter.addOne(workspace, state.workspaces),
  };
};
const update = (state: State, { id, changes }) => {
  // refresh the current workspace is current
  let currentWorkspace = state.currentWorkspace;
  if (state.currentWorkspace?.id === id) {
    currentWorkspace = { ...state.currentWorkspace, ...changes };
  }
  return {
    ...state,
    workspaces: workspacesEntityAdapter.updateOne({ id, changes }, state.workspaces),
    currentWorkspace,
  };
};
const remove = (state: State, { workspace }) => {
  return {
    ...state,
    workspaces: workspacesEntityAdapter.removeOne(workspace.id, state.workspaces),
  };
};
const setCurrentWorkspace = (state: State, { workspace }) => {
  return {
    ...state,
    currentWorkspace: workspace,
  };
};

export const workspacesReducers = createReducer(
  workspacesInitialState,
  on(WorkspacesActions.loadWorkspacesSuccess, setData),
  on(WorkspacesActions.clearWorkspaces, clearData),
  on(WorkspacesActions.createWorkspaceSuccess, add),
  on(WorkspacesActions.deleteWorkspaceSuccess, remove),
  on(WorkspacesActions.updateWorkspaceSuccess, update),
  on(WorkspacesActions.loadCurrentWorkspaceSuccess, setCurrentWorkspace),
);

export function reducer(state: State | undefined, action: Action): any {
  return workspacesReducers(state, action);
}
