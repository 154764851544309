<div id="sidenav">
  <img class="logo" (click)="goHome()" src="assets/images/vibeiq_large.jpg" />

  <div class="w-full pl-4 mb-4 mt-[10px]">
    <app-workspace-selector></app-workspace-selector>
  </div>

  <ng-container *ngFor="let navOption of navOptions">
    <div
      class="nav-link"
      [routerLink]="[navOption.routerLink]"
      [queryParams]="navOption?.queryParams"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <div class="mr-6 flex items-center">
        <mat-icon *ngIf="navOption?.matIcon">{{ navOption.icon }}</mat-icon>
        <img
          *ngIf="!navOption?.matIcon"
          [src]="'assets/images/' + navOption.icon"
          class="w-5 h-5 object-contain img-inactive"
        />
        <img
          *ngIf="!navOption?.matIcon"
          [src]="'assets/images/' + navOption.iconActive"
          class="w-5 h-5 object-contain img-active"
        />
      </div>
      <span class="text-title-2">{{ navOption.label }}</span>
    </div>
  </ng-container>

  <mat-divider class="!mt-6 pb-3"></mat-divider>

  <div class="ml-4 flex flex-col" *ngIf="false">
    <div class="nav-item text-title-2" (click)="helpCenter()">
      <span class="material-icons text-black60 mr-6"> help_outline </span>
      Get Help
    </div>
  </div>
</div>
