import { Component, OnInit } from '@angular/core';
import { AuthService, OrgMembership, User } from 'src/app/common/auth/auth.service';

@Component({
  selector: 'app-org-selector',
  templateUrl: './org-selector.component.html',
  styleUrls: ['./org-selector.component.scss'],
})
export class OrgSelectorComponent implements OnInit {
  constructor(private authService: AuthService) {}
  selectedOrg: any;
  visibleOrgs: Array<OrgMembership> = [];

  async ngOnInit(): Promise<void> {
    const user: User = await this.authService.getCurrentUser();
    this.visibleOrgs = user.orgs?.filter((org) => !org?.isArchived) ?? [];
    this.selectedOrg = this.visibleOrgs[0];
    this.authService.authContext.subscribe((context) => {
      this.selectedOrg = context?.currentOrg;
    });
  }

  selectOrg(org) {
    this.selectedOrg = org;
    document.location.href = `/org/${org.orgSlug}/showrooms`;
  }
}
