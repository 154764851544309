import { createAction, props } from '@ngrx/store';
import { Workspace } from './workspaces.state';

export enum WorkspacesActionTypes {
  LOAD_WORKSPACES = '[Workspaces] Load Workspaces',
  LOAD_WORKSPACES_SUCCESS = '[Workspaces] Load Workspaces Success',
  LOAD_WORKSPACES_FAILURE = '[Workspaces] Load Workspaces Failure',

  CLEAR_WORKSPACES = '[Workspaces] Clear Workspaces',

  CREATE_WORKSPACE = '[Workspaces] Create Workspace',
  CREATE_WORKSPACE_SUCCESS = '[Workspaces] Create Workspace Success',
  CREATE_WORKSPACE_FAILURE = '[Workspaces] Create Workspace Failure',

  DELETE_WORKSPACE = '[Workspaces] Delete Workspace',
  DELETE_WORKSPACE_SUCCESS = '[Workspaces] Delete Workspace Success',
  DELETE_WORKSPACE_FAILURE = '[Workspaces] Delete Workspace Failure',

  UPDATE_WORKSPACE = '[Workspaces] Update Workspace',
  UPDATE_WORKSPACE_SUCCESS = '[Workspaces] Update Workspace Success',
  UPDATE_WORKSPACE_FAILURE = '[Workspaces] Update Workspace Failure',

  LOAD_CURRENT_WORKSPACE = '[Workspaces] Load Current Workspace',
  LOAD_CURRENT_WORKSPACE_SUCCESS = '[Workspaces] Load Current Workspace Success',
  LOAD_CURRENT_WORKSPACE_FAILURE = '[Workspaces] Load Current Workspace Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const clearWorkspaces = createAction(WorkspacesActionTypes.CLEAR_WORKSPACES);
export const loadWorkspaces = createAction(WorkspacesActionTypes.LOAD_WORKSPACES);

export const loadWorkspacesSuccess = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadWorkspacesFailure = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACES_FAILURE,
  props<{ error: any }>(),
);

export const createWorkspace = createAction(WorkspacesActionTypes.CREATE_WORKSPACE, props<{ workspace: Workspace }>());

export const createWorkspaceSuccess = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const createWorkspaceFailure = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const updateWorkspace = createAction(
  WorkspacesActionTypes.UPDATE_WORKSPACE,
  props<{ id: string; changes: Workspace }>(),
);

export const updateWorkspaceSuccess = createAction(
  WorkspacesActionTypes.UPDATE_WORKSPACE_SUCCESS,
  props<{ id: string; changes: Workspace }>(),
);

export const updateWorkspaceFailure = createAction(
  WorkspacesActionTypes.UPDATE_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const deleteWorkspace = createAction(WorkspacesActionTypes.DELETE_WORKSPACE, props<{ workspace: Workspace }>());

export const deleteWorkspaceSuccess = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const deleteWorkspaceFailure = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentWorkspace = createAction(WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE, props<{ id: string }>());

export const loadCurrentWorkspaceSuccess = createAction(
  WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const loadCurrentWorkspaceFailure = createAction(
  WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);
