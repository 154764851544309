import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootStoreState, UserSessionSelectors, UserSessionActions } from 'src/app/root-store';
import { AuthService } from '../../auth/auth.service';
import { UserSession } from '../user-session';

@Component({
  selector: 'app-user-sessions-avatars',
  templateUrl: './user-sessions-avatars.component.html',
  styleUrls: ['./user-sessions-avatars.component.scss'],
})
export class UserSessionsAvatarsComponent implements OnInit {
  userSessions$: Observable<Array<UserSession>>;
  lastUserSession: UserSession;
  user: any;
  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.userSessions$ = this.store.select(UserSessionSelectors.selectUserSessions);
    this.authService.authContext.subscribe((authContext) => {
      this.user = authContext.user;
    });
  }
}
