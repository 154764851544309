import { AssortmentsStoreState } from '../common/assortments/assortments-store';
import { AuthStoreState } from '../common/auth/auth-store';
import { CommentsStoreState } from '../common/comments/comments-store';
import { LoadingIndicatorStoreState } from '../common/loading-indicator/loading-indicator-store';
import { UserSessionStoreState } from '../common/user-sessions/user-sessions-store';
import { ShowroomsStoreState } from '../showrooms/showrooms-store';
import { WorkspacesStoreState } from '../workspaces/workspaces-store';
export interface State {
  userSessions: UserSessionStoreState.State;
  auth: AuthStoreState.State;
  comments: CommentsStoreState.State;
  loadingIndicator: LoadingIndicatorStoreState.State;
  workspaces: WorkspacesStoreState.State;
  assortments: AssortmentsStoreState.State;
  showrooms: ShowroomsStoreState.State;
}
