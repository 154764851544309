<div class="header-left">
  <app-page-header></app-page-header>
</div>
<div class="right-menu">
  <app-user-sessions-avatars></app-user-sessions-avatars>
  <app-help-selector *ngIf="false" class="mr-1"></app-help-selector>
  <app-app-switch class="mr-2"></app-app-switch>
  <app-org-selector></app-org-selector>
  <app-header-user-menu></app-header-user-menu>
</div>
