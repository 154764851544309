import { State } from '../assortments.state';
import { backingAssortmentItemEntityAdapter } from './backing-assortment.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as BackingAssortmentItemActionTypes from './backing-assortment.actions';

const loadBackingAssortment = (state: State, { assortment }) => {
  const assortmentItems = [...assortment.assortmentItems];
  const assortmentCopy = Object.assign({}, assortment);
  delete assortmentCopy.assortmentItems;
  return {
    ...state,
    backingAssortmentItems: backingAssortmentItemEntityAdapter.setAll(assortmentItems, state.backingAssortmentItems),
    backingAssortment: assortmentCopy,
  };
};
const setAssortmentItems = (state: State, { data }) => {
  return {
    ...state,
    backingAssortmentItems: backingAssortmentItemEntityAdapter.setAll(data, state.backingAssortmentItems),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    backingAssortmentItems: backingAssortmentItemEntityAdapter.removeAll(state.backingAssortmentItems),
  };
};
const addAssortmentItem = (state: State, { entity }) => {
  return {
    ...state,
    backingAssortmentItems: backingAssortmentItemEntityAdapter.addOne(entity, state.backingAssortmentItems),
  };
};

const addAssortmentItems = (state: State, { assortmentItems }) => {
  return {
    ...state,
    backingAssortmentItems: backingAssortmentItemEntityAdapter.addMany(assortmentItems, state.backingAssortmentItems),
  };
};

const updateAssortmentItem = (state: State, { id, changes }) => {
  return {
    ...state,
    backingAssortmentItems: backingAssortmentItemEntityAdapter.updateOne({ id, changes }, state.backingAssortmentItems),
  };
};

const updateAssortmentItems = (state: State, { changes }) => {
  return {
    ...state,
    backingAssortmentItems: backingAssortmentItemEntityAdapter.updateMany(changes, state.backingAssortmentItems),
  };
};

const removeAssortmentItem = (state: State, { entity }) => {
  return {
    ...state,
    backingAssortmentItems: backingAssortmentItemEntityAdapter.removeOne(entity.id, state.backingAssortmentItems),
  };
};
const removeAssortmentItems = (state: State, { ids }) => {
  return {
    ...state,
    backingAssortmentItems: backingAssortmentItemEntityAdapter.removeMany(ids, state.backingAssortmentItems),
  };
};

export const backingAssortmentReducers = [
  on(BackingAssortmentItemActionTypes.loadBackingAssortmentSuccess, loadBackingAssortment),
  on(BackingAssortmentItemActionTypes.addItemsToBackingAssortmentSuccess, addAssortmentItems),
  on(BackingAssortmentItemActionTypes.removeItemsFromBackingAssortmentSuccess, removeAssortmentItems),
];
