<div *ngIf="!showroomEdit" class="title-bar">
  <div class="showroom-logo flex items-center ml-4 cursor-pointer" (click)="gotoHome()">
    <img src="assets/images/showroom_icon.svg" class="w-9 h-9 object-contain mr-4" />
    <h6>Showroom</h6>
  </div>
</div>

<div *ngIf="showroomEdit" class="title-bar">
  <div class="flex items-center">
    <img
      src="assets/images/showroom_icon.svg"
      class="w-9 h-9 object-contain mx-4 cursor-pointer"
      (click)="gotoHome()"
    />

    <div class="title mr-3">
      <h6 *ngIf="!editName">{{ showroom?.name }}</h6>
      <form [formGroup]="form">
        <mat-form-field *ngIf="editName">
          <input
            matInput
            #editNameInput
            type="text"
            formControlName="name"
            id="name"
            name="name"
            (blur)="endEditName()"
          />
        </mat-form-field>
      </form>
    </div>

    <button id="edit_button" mat-button class="btn-32 !mr-2" (click)="startNameEdit()">
      <mat-icon>edit</mat-icon>
    </button>

    <app-menu name="" icon="settings">
      <div>Settings</div>
    </app-menu>
  </div>
</div>
