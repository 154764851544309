import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserSessionsStoreModule } from '../common/user-sessions/user-sessions-store';
import { AuthStoreModule } from '../common/auth/auth-store';
import { CommentsStoreModule } from '../common/comments/comments-store';
import { LoadingIndicatorStoreModule } from '../common/loading-indicator/loading-indicator-store';
import { AssortmentsStoreModule } from '@common/assortments/assortments-store';
import { ShowroomsStoreModule } from '../showrooms/showrooms-store/showrooms-store.module';
import { WorkspacesStoreModule } from '../workspaces/workspaces-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    UserSessionsStoreModule,
    AuthStoreModule,
    CommentsStoreModule,
    LoadingIndicatorStoreModule,
    WorkspacesStoreModule,
    AssortmentsStoreModule,
    ShowroomsStoreModule,
  ],
})
export class RootStoreModule {}
