import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ListCardBadge {
  text: string;
  color?: string;
}
@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
})
export class ListCardComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() title: string;
  @Input() updatedOn: any;
  @Input() iconUrl: string;
  @Input() badge: ListCardBadge;
  @Output() openMenu = new EventEmitter();
  selected = false;

  constructor() {}

  ngOnInit(): void {}

  showMenu($event) {
    $event.stopPropagation();
    this.openMenu.emit($event);
  }
}
