import { State } from './showrooms.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as ShowroomsActions from './showrooms.actions';
import { showroomsEntityAdapter } from './showrooms.state';
import { showroomsInitialState } from './showrooms.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    showrooms: showroomsEntityAdapter.setAll(data, state.showrooms),
    showroomsLoaded: true,
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    showrooms: showroomsEntityAdapter.removeAll(state.showrooms),
    showroomsLoaded: false,
  };
};
const add = (state: State, { showroom }) => {
  return {
    ...state,
    showrooms: showroomsEntityAdapter.addOne(showroom, state.showrooms),
  };
};
const update = (state: State, { id, changes }) => {
  let currentShowroom = state.currentShowroom;
  if (state.currentShowroom?.id === id) {
    currentShowroom = { ...state.currentShowroom, ...changes };
  }
  return {
    ...state,
    showrooms: showroomsEntityAdapter.updateOne({ id, changes }, state.showrooms),
    currentShowroom,
  };
};
const remove = (state: State, { showroom }) => {
  return {
    ...state,
    showrooms: showroomsEntityAdapter.removeOne(showroom.id, state.showrooms),
  };
};
const setCurrentShowroom = (state: State, { showroom }) => {
  return {
    ...state,
    currentShowroom: showroom,
  };
};

export const showroomsReducers = createReducer(
  showroomsInitialState,
  on(ShowroomsActions.loadShowroomsSuccess, setData),
  on(ShowroomsActions.clearShowrooms, clearData),
  on(ShowroomsActions.createShowroomSuccess, add),
  on(ShowroomsActions.deleteShowroomSuccess, remove),
  on(ShowroomsActions.updateShowroomSuccess, update),
  on(ShowroomsActions.loadCurrentShowroomSuccess, setCurrentShowroom),
);

export function reducer(state: State | undefined, action: Action): any {
  return showroomsReducers(state, action);
}
