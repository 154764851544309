import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ShowroomsSelectors } from '../showrooms/showrooms-store';
import { PageHeaderService } from './header/page-header/page-header.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  sideVisible = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<RootStoreState.State>,
    private pageHeaderService: PageHeaderService,
  ) {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.sideVisible = evt?.urlAfterRedirects.split('/').includes('showrooms') ? true : false;
      }
    });
  }

  ngOnInit(): void {}
}
