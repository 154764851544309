import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { RootStoreState } from 'src/app/root-store';
import { ShowroomsActions } from '.';
import { Showroom } from './showrooms.state';
import { ShowroomsService } from '../showrooms.service';
import { WorkspacesActions } from 'src/app/workspaces/workspaces-store';

@Injectable()
export class ShowroomsEffects {
  constructor(
    private actions$: Actions,
    private showroomService: ShowroomsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private webSocketService: WebSocketService,
  ) {}
  loadShowrooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowroomsActions.ShowroomsActionTypes.LOAD_SHOWROOMS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.showroomService.getShowrooms(store.workspaces.currentWorkspace.id)).pipe(
          map((data) => ShowroomsActions.loadShowroomsSuccess({ data })),
          catchError((error) => observableOf(ShowroomsActions.loadShowroomsFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentShowrooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowroomsActions.ShowroomsActionTypes.LOAD_CURRENT_SHOWROOM),
      switchMap((action: any) => {
        return from(this.showroomService.getShowroomById(action.id)).pipe(
          map((data) => ShowroomsActions.loadCurrentShowroomSuccess({ showroom: data })),
          catchError((error) => observableOf(ShowroomsActions.loadCurrentShowroomFailure({ error }))),
        );
      }),
    ),
  );
  createShowroom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowroomsActions.ShowroomsActionTypes.CREATE_SHOWROOM),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const showroom: Showroom = { ...action.showroom };
        showroom.workspaceId = store.workspaces.currentWorkspace.id;
        return from(this.showroomService.createShowroom(showroom)).pipe(
          map((data) => {
            this.snackBar.open('Showroom Created.', '', { duration: 2000 });
            return ShowroomsActions.createShowroomSuccess({ showroom: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(ShowroomsActions.createShowroomFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteShowroom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowroomsActions.ShowroomsActionTypes.DELETE_SHOWROOM),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.showroomService.deleteShowroom(action.showroom)).pipe(
          map((data) => {
            this.snackBar.open('Showroom Deleted.', '', { duration: 2000 });
            return ShowroomsActions.deleteShowroomSuccess({ showroom: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(ShowroomsActions.deleteShowroomFailure({ error }));
          }),
        );
      }),
    ),
  );
  updateShowroom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowroomsActions.ShowroomsActionTypes.UPDATE_SHOWROOM),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.showroomService.updateShowroom(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Showroom Updated.', '', { duration: 2000 });
            return ShowroomsActions.updateShowroomSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(ShowroomsActions.updateShowroomFailure({ error }));
          }),
        );
      }),
    ),
  );
}
