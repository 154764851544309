import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { ColorUtil } from 'src/app/common/util/color-util';
import { Workspace } from '../workspaces-store/workspaces.state';

@Component({
  selector: 'app-workspace-card',
  templateUrl: './workspace-card.component.html',
  styleUrls: ['./workspace-card.component.scss'],
})
export class WorkspaceCardComponent implements OnChanges {
  @Input() workspace: Workspace;
  public selected: boolean;
  public initialsBackgroundColor: string;
  public initials: string;
  constructor(private routingService: RoutingService) {}

  ngOnChanges(): void {
    if (this.workspace?.name) {
      this.initials = this.workspace.name.slice(0, 2);
      this.initialsBackgroundColor = ColorUtil.stringToHslaColor(this.initials);
    }
  }
  goToDetails(event) {
    this.routingService.go('/workspaces/workspace/' + this.workspace.id);
  }
}
