import { TypeProperty } from '@contrail/types';
import { Action, createReducer, on } from '@ngrx/store';
import * as CommentsActions from './comments.actions';
import { initialState, State } from './comments.state';

const loadComments = (state: State, { contextReference }) => {
  return {
    ...state,
    contextReference,
  };
};
const loadCommentsSuccess = (state: State, { data }) => {
  return {
    ...state,
    contextComments: data,
  };
};
const addComment = (state: State, { comment }) => {
  return {
    ...state,
    contextComments: [...state.contextComments, comment],
  };
};
const updateComment = (state: State, { comment }) => {
  const index = state.contextComments.findIndex((o: any) => o.id === comment.id);
  const newArray = [...state.contextComments];
  newArray[index] = comment;
  return {
    ...state,
    contextComments: newArray,
  };
};
const removeComment = (state: State, { comment }) => {
  return {
    ...state,
    contextComments: [...state.contextComments.filter((com: any) => com.id !== comment.id)],
  };
};

const toggleCommentsSideBar = (state: State) => {
  return {
    ...state,
    showCommentsSideBar: !state.showCommentsSideBar,
  };
};
const showCommentOverlay = (state: State, { ownerInfo, property = null, position }) => {
  return {
    ...state,
    commentOwnerInfo: ownerInfo,
    commentProperty: property,
    commentOverlayPosition: position,
    showCommentOverlay: true,
  };
};
const hideCommentOverlay = (state: State, {}) => {
  return {
    ...state,
    showCommentOverlay: false,
  };
};
export const commentsReducer = createReducer(
  initialState,
  on(CommentsActions.loadCommentsSuccess, loadCommentsSuccess),
  on(CommentsActions.loadComments, loadComments),
  on(CommentsActions.createCommentSuccess, addComment),
  on(CommentsActions.addComment, addComment),
  on(CommentsActions.updateCommentSuccess, updateComment),
  on(CommentsActions.deleteCommentSuccess, removeComment),
  on(CommentsActions.showCommentOverlay, showCommentOverlay),
  on(CommentsActions.hideCommentOverlay, hideCommentOverlay),
  on(CommentsActions.toggleCommentsSideBar, toggleCommentsSideBar),
  on(CommentsActions.modifyComment, updateComment),
  on(CommentsActions.removeComment, removeComment),
);

export function reducer(state: State | undefined, action: Action): any {
  return commentsReducer(state, action);
}
